import {action, configure, computed, makeObservable, observable} from 'mobx';

import events from 'events.js';
import {MOBILE_MAX_SCREEN_SIZE, TABLET_MAX_SCREEN_SIZE} from 'base/search.js';
import {deviceDetecter} from 'core/utils.js';

configure({
    useProxies: 'never',
});

export default class Store {
    constructor() {
        makeObservable(this, {
            updateStore: action,

            isMobile: observable,
            isMobileOrTablet: observable,

            clientLocalTime: computed,
            deviceInfo: computed,
        });
        this.handleWindowResize();
        this.listenToWindowResize();
    }
    isMobile = false;
    isMobileOrTablet = false;

    get deviceInfo() {
        return {
            device: deviceDetecter(),
            userAgent: window.navigator.userAgent,
            screenSize: `${window.innerWidth} * ${window.innerHeight}`,
        };
    }

    get clientLocalTime() {
        return new Date().toLocaleString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
        });
    }

    updateStore(properties) {
        Object.keys(properties).map((key) => {
            this[key] = properties[key];
        });
    }

    listenToWindowResize() {
        events.listen(window, ['resize', 'orientationchange'], () => {
            this.handleWindowResize();
        });
    }

    handleWindowResize() {
        const isMobile = window.matchMedia(
            `(max-width: ${MOBILE_MAX_SCREEN_SIZE})`,
        ).matches;
        const isMobileOrTablet = window.matchMedia(
            `(max-width: ${TABLET_MAX_SCREEN_SIZE})`,
        ).matches;
        this.updateStore({isMobile, isMobileOrTablet});
    }

    throwError(error) {
        throw new Error(error);
    }
}
