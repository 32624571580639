export function snakeToCamel(text) {
    if (typeof text === 'string' || text instanceof String) {
        const parts = text.split(/_/);
        const camelText =
            parts[0] +
            parts
                .splice(1)
                .map(
                    (val) =>
                        val.substring(0, 1).toUpperCase() + val.substring(1),
                )
                .join('');
        return camelText;
    }
    return text;
}

export function camelToSnake(text) {
    if (typeof text === 'string' || text instanceof String) {
        const parts = text.split(/(?=[A-Z])/);
        const snakeText = parts.join('_').toLowerCase();
        return snakeText;
    }
    return text;
}

export function snakeToCamelObjectKeys(obj) {
    if (obj !== null) {
        const newObj = {};
        for (const key of Object.keys(obj)) {
            const newKey = snakeToCamel(key);
            let newVal = obj[key];

            if (newVal instanceof Array) {
                newVal = newVal.map((item) => {
                    if (item instanceof Object) {
                        return snakeToCamelObjectKeys(item);
                    }
                    return item;
                });
            } else if (newVal instanceof Object) {
                newVal = snakeToCamelObjectKeys(newVal);
            }
            newObj[newKey] = newVal;
        }
        return newObj;
    }
    return obj;
}

export function camelToSnakeObjectKeys(obj) {
    if (obj !== null) {
        const newObj = {};
        for (const key of Object.keys(obj)) {
            const newKey = camelToSnake(key);
            let newVal = obj[key];

            if (newVal instanceof Array) {
                newVal = newVal.map((item) => {
                    if (item instanceof Object) {
                        return camelToSnakeObjectKeys(item);
                    }
                    return item;
                });
            } else if (newVal instanceof Object) {
                newVal = camelToSnakeObjectKeys(newVal);
            }
            newObj[newKey] = newVal;
        }
        return newObj;
    }
    return obj;
}

export function snakeToCamelObjectValues(obj) {
    if (obj !== null) {
        Object.keys(obj).forEach((item) => {
            if (obj[item] instanceof Array) {
                obj[item] = obj[item].map((arrayItem) => {
                    if (arrayItem instanceof Object) {
                        return snakeToCamelObjectValues(arrayItem);
                    }
                    return arrayItem;
                });
            } else if (obj[item] instanceof Object) {
                obj[item] = snakeToCamelObjectValues(obj[item]);
            } else {
                obj[item] = snakeToCamel(obj[item]);
            }
        });
    }
    return obj;
}
