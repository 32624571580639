export default function autobind(target, key, descriptor) {
    const fn = descriptor.value;
    if (typeof fn !== 'function') {
        throw new SyntaxError(
            '@autobind decorator can only be applied to methods',
        );
    }

    return {
        configurable: true,
        get() {
            const boundFn = fn.bind(this);
            Object.defineProperty(this, key, {
                configurable: true,
                value: boundFn,
                writable: true,
            });
            return boundFn;
        },
        set(value) {
            Object.defineProperty(this, key, {
                configurable: true,
                value,
                writable: true,
            });
        },
    };
}
